import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CardsCredit from "./CardsCredit"
import "./Ofrecemos.css"
import { BannerNoticeClient } from "./NoticeClient/BannerNoticeClient"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 15,
    textAlign: "rigth",
    width: 350,
  },
  margintext: {
    margin: theme.spacing(4, 0),
  },
}))

export default function HowItWorks() {
  const classes = useStyles()
  return (
    <div className={classes.root + " backgroundOSol"} id="Credito">
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid className={classes.description} item xs={12}>
            <Typography align="center" variant="overline" component="p">
              Financiamiento
            </Typography>
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              className={classes.marginText}
            >
              ¿Qué ofrecemos a <strong>nuestros solicitantes?</strong>
            </Typography>
          </Grid>
          <Grid
            item
            lg={10}
            container
            spacing={4}
            justify="space-around"
            alignItems="center"
          >
            <CardsCredit
              title="Liquidez"
              content1="Pesos Mexicanos"
              content2="Plazo de 12 a 120 meses"
              number="300,000"
              union="Hasta"
              color="secondary"
              prestamo="Préstamo"
            />

            <CardsCredit
              title="Liquidez de empresa"
              content1="Pesos Mexicanos"
              content2="Plazo de 12 a 120 meses"
              number="10,000,000"
              union="Hasta"
              color="secondary"
              prestamo="Préstamo"
            />

            <CardsCredit
              title="Hogar"
              content1="Pesos Mexicanos"
              content2="Plazo de 12 a 120 meses"
              number="300,000"
              union="Hasta"
              color="secondary"
              prestamo="Préstamo"
            />
          </Grid>
          <BannerNoticeClient />
        </Grid>
      </Container>
    </div>
  )
}
