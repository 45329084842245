import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography
} from "@material-ui/core"
import Retorno from "../assets/Howitworks/retorno.svg"
import Financiamiento from "../assets/Howitworks/investment.svg"
import Inversion from "../assets/Howitworks/finace.svg"

const useStyles = makeStyles({
  root: {
    padding: "8px 16px"
  },
  height: {
    height: "100%"
  },
  media: {
    width: "100%",

    maxWidth: "100px"
  }
})

export default function CardHIW(props) {
  const classes = useStyles()
  const ImgSrc = () => {
    switch (props.image) {
      case "Retorno":
        return (
          <div>
            <img
              className={classes.media}
              src={Retorno}
              alt={props.imageTitle}
            />
          </div>
        )
      case "Financiamiento":
        return (
          <div>
            <img
              className={classes.media}
              src={Financiamiento}
              alt={props.imageTitle}
            />
          </div>
        )
      case "Inversion":
        return (
          <div>
            <img
              className={classes.media}
              src={Inversion}
              alt={props.imageTitle}
            />
          </div>
        )
      default:
        return (
          <div>
            <img
              className={classes.media}
              src={Inversion}
              alt={props.imageTitle}
            />
          </div>
        )
    }
  }
  return (
    <Grid item lg={6} sm={12}>
      <Card className={classes.height}>
        <CardContent>
          <CardActionArea>
            <Grid container spacing={2}>
              <Grid container item xs={3} justify="center" alignItems="center">
                <ImgSrc />
              </Grid>
              <Grid item xs={9}>
                <Typography noWrap gutterBottom variant="overline">
                  {props.title}
                </Typography>
                <Typography variant="body1">{props.content}</Typography>
              </Grid>
            </Grid>
          </CardActionArea>
        </CardContent>
      </Card>
    </Grid>
  )
}
CardHIW.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  content: PropTypes.string,
  imageTitle: PropTypes.string
}
