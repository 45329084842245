import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import {Typography, Grid, Button, Container} from "@material-ui/core"
import Title from "./Title"

import Hero from "../assets/Hero/Hero.png"

import "../../src/assets/Styles/Home.css"

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh"
    }, container: {
        // position: "relative",
        width: '100%'
    }, tryButton: {
        borderRadius: '8px',
        color: 'white',
        borderColor: 'white',
        marginLeft: "1em",
        '@media (max-width: 499px)': {margin: '0', width: '100%'},
        '&:hover': {backgroundColor: '#ffffff', color: 'black'}
    }, investButton: {
        borderRadius: '8px',
        background: 'white',
        color: 'black',
        marginTop: 0,
        marginRight: "1em",
        '@media (max-width: 499px)': {margin: '0 0 20px 0', width: '100%'},
        '&:hover': {color: '#ffffff', backgroundColor: 'transparent', border: '1px white solid'}
    }, buttonsContainer: {
        marginTop: theme.spacing(3), textAlign: "start"
    }, textContainer: {
        margin: theme.spacing(3, 0)
    }, titleContainer: {
        margin: theme.spacing(8, 0, 0, 0), color: '#FFFFFF',
    }, mainTitle: {
        margin: '30px 0 60px 0', fontWeigth: 800, fontsize: '1.8rem'
    }, subTitle: {
        marginBottom: '60px'
    }, title: {
        position: "relative", '@media (max-width: 470px)': {width: '90%'}
    }, bgTopNavCircle: {
        position: "absolute", top: 0, right: "-10%", zIndex: "-1"
    }, bgSideCircle: {
        position: "absolute", top: "30%", left: "-15%", width: "300px", zIndex: "-1"
    }, bgCitySkyline: {
        background: `url(${Hero}) `,
        backgroundPosition: 'bottom right',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowX: "hidden",
        marginTop: '125px',
        '@media (max-width: 799px)': {backgroundPosition: '80% 80%'}
    },
}))

const Buttons = () => {
    const classes = useStyles()


    return (<div className={classes.buttonsContainer}>
            <Button
                size="large"
                variant="contained"
                className={classes.investButton}
                href={`${process.env.GATSBY_APP_LENDER}/register/email?profileType=personal&lang=es-mx`}
            >
                Invierte tu dinero
            </Button>
            <Button
                size="large"
                href="/Registro"
                className={classes.tryButton}
                variant="outlined"
            >
                Solicita un crédito
            </Button>
        </div>)
}

const Home = () => {
    const classes = useStyles()
    return (<div className={classes.bgCitySkyline}>
            <Container className={classes.container}>
                <Grid
                    className={classes.root}
                    container
                    justify="start"
                    alignItems="up"
                >
                    <Grid item xs={12} lg={8} className={classes.titleContainer}>
                        <Title className={classes.title}/>
                        <Typography gutterBottom variant="h4" align="start" className={classes.mainTitle}>
                            Capital Funding Lab es una plataforma de financiamiento colectivo con presencia en México
                            que está conectando a inversionistas con solicitantes rigurosamente seleccionados.
                            {/* <br /><br />
              Todos los financiamientos generados a través de nuestra plataforma están respaldados con garantías reales. */}
                        </Typography>
                        <Typography gutterBottom variant="h5" align="start" className={classes.subTitle}>
                            Todos los financiamientos generados a través de nuestra plataforma están respaldados con
                            garantías reales.
                        </Typography>
                        <Buttons/>
                    </Grid>
                    {/* <img alt="Grafica" src={Grafica} className={"grafica"} /> */}
                    {/* <img src={TopNavCircle} className={classes.bgTopNavCircle} alt="" />
          <img src={SideCircle} className={classes.bgSideCircle} alt="" /> */}
                </Grid>
            </Container>
        </div>)
}

Home.propTypes = {}

export default Home


