import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, Box, Container, Button } from "@material-ui/core"
import CalculatorFinace from "./CalculatorFinace"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage:
      "linear-gradient(to bottom, #E0F7FA 5%, #ffffff 95%) !important",
    margin: 0,
    padding: theme.spacing(6, 0)
  },
  textMargin: {
    padding: "10px 2rem",
    maxWidth: "480px"
  },
  textStyle2: {
    padding: theme.spacing(6, 0)
  },
  buttonR: {
    borderRadius: "50px",
    margin: theme.spacing(6, 0),
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.black,
      background: theme.palette.common.white
    }
  },
  container: {
    padding: theme.spacing(0, 6)
  },
  paddingB: {
    paddingBottom: theme.spacing(6)
  },
  resText: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
  },
  containerButton: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center'
    },
  }
}))

export default function Finance() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid
            container
            spacing={4}
            justify="space-between"
            lg={10}
            md={5}
            item
          >
            <Grid item xs={12} lg={6}>
              <Typography variant="h4" className={classes.resText}>
                <strong>Beneficios</strong>
              </Typography>
              <Typography className={classes.paddingB + ' ' + classes.resText}>
                Bajos costos de mantenimiento.
                <br />
                Tasa diferencial por buen comportamiento.
                <br />
                Cancelación total o parcial.
                <br />
                Te damos respuesta inmediata.
              </Typography>
              <Grid>
                <div>
                  <Typography variant="h4" className={classes.resText}>
                    <strong>Requisitos para solicitante</strong>
                  </Typography>
                  <Typography variant="body1" className={classes.paddingB + ' ' + classes.resText}>
                    Ser mexicano, con residencia permanente en el país.
                    <br />
                    Mayor de 18 años.
                    <br />
                    Ingreso mensual neto de $6,250.
                    <br />
                    Tener cuenta en un banco, e-mail y celular.
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <div>
                  <Typography variant="h4" className={classes.resText}>
                    <strong>Comisiones para solicitantes</strong>
                  </Typography>
                  <Typography variant="body1" className={classes.paddingB + ' ' + classes.resText}>
                    Comisión por apertura:
                    <br />
                    Del 3.5% al 30% dependiendo de la calificación crediticia y el plazo de financiamiento
                    <br />
                    Gastos de Administración:
                    <br />
                    2.5% de forma anual sobre la línea del crédito o pagadero mensual de forma proporcional.
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <div>
                  <Typography variant="h4" className={classes.resText}>
                    <strong>Comisiones para inversionistas</strong>
                  </Typography>
                  <Typography variant="body1" className={classes.paddingB + ' ' + classes.resText}>
                    Comisión por éxito:
                    <br />
                    Diferencia entre la tasa de interés pagada por el acreditado y la rentabilidad esperada por el acreditante.
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <Typography variant="h2" className={classes.typStyle + ' ' + classes.resText}>
                  Los primeros pasos son <br /> los{" "}
                  <strong>más importantes</strong>
                </Typography>
              </Grid>
              <Grid container className={classes.containerButton}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonR}
                  href='/Registro'
                >
                  Solicita un crédito
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={"auto"}>
              <Box maxWidth="350px">
                <CalculatorFinace />
                <Typography variant="body2" className={classes.resText}>
                  El resultado de "Calcula tu crédito", se basa exclusivamente en la información crediticia incorporada en el formulario. Te informaremos de la tasa de interés del préstamo y el valor de cuota promedio que te podamos ofrecer una vez que finalices la Solicitud de Crédito. Las mismas podrán ser diferentes al resultado de "Calcula tu crédito".
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
