import React from "react"
import { Typography, Box } from "@material-ui/core"
import DisplayChild from "./DisplayChild"

function Display({ interest, amount, years }) {

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0
  })

  const calculateMonthlyRepayment = () => {
    const monthlyRepayment = amount * (1 + interest / 100) ** (years / 12)
    return (
      <Typography variant="h4">
        <strong>{(formatter.format(monthlyRepayment))}</strong>
      </Typography>
    )
  }

  const percentageAPR = () => {
    return (
      <Typography variant="h4">
        <strong>{interest}%</strong>
      </Typography>
    )
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <DisplayChild func={percentageAPR()} text="Porcentaje de interés estimado" />
      <DisplayChild
        func={calculateMonthlyRepayment()}
        text="Retorno Estimado"
      />
    </Box>
  )
}

export default Display
