import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import CardHIW from "./CardHIW"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#EDECF3"
  },
  cardTitle: {
    fontSize: 16
  },
  subtitle: {
    fontSize: 15,
    textAlign: "rigth",
    width: 350
  },
  marginT: {
    margin: theme.spacing(4, 0)
  },
  height100: {
    minHeight: "80vh"
  }
}))

export default function HowItWorks() {
  const classes = useStyles()
  return (
    <div className={classes.root} id="HIW">
      <Container>
        <Grid
          className={classes.height100}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item container justify="center" xs={12} lg={12}>
            <Box maxWidth="80%" m="auto">
              <Typography variant="h2" align="center">
                ¿Cómo <b>funciona?</b>
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                className={classes.marginT}
              >
                Basamos nuestro modelo de negocio en el Crowdlending, generando
                financiamiento colectivo de forma automática y autónoma.
              </Typography>
            </Box>

            <Grid
              item
              container
              spacing={3}
              justify="center"
              sm={8}
              lg={10}
              wrap="wrap"
            >
              <CardHIW
                title="Inversión"
                content="Cada usuario inversionista ingresa el capital que está dispuesto a invertir."
                image="Inversion"
                imageTitle="Inversión"
              />
              <CardHIW
                title="Financiamiento"
                content="Tras un exhaustivo proceso de evaluación, los solicitantes de crédito reciben financiamiento de diversos inversionistas a través de nuestro sistema automático de asignación."
                image="Financiamiento"
                imageTitle="Financiamiento"
              />
              <CardHIW
                title=" Retorno de Inversión"
                content="Los inversionistas obtienen el retorno de su inversión a través de los pagos que mensualmente realicen los solicitantes de créditos, respecto de los préstamos desembolsados a estos."
                image="Retorno"
                imageTitle="Retorno de Inversión"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
