import React from "react"
import PropTypes from "prop-types"
import { Typography, Box } from "@material-ui/core"
const DisplayChild = ({ func, text }) => {
  return (
    <Box pr={1}>
      <Typography variant="body2">{text}</Typography>
      {func}
    </Box>
  )
}

DisplayChild.defaultProps = {
  func: () => <p>Missing numeric value</p>,
  text: "No value provided"
}

DisplayChild.propTypes = {
  func: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
}

export default DisplayChild
