import React from "react"
import { Box, Typography } from "@material-ui/core"
import Logo from "../../assets/Logo/logo-cfl-dark.svg"

const styleScroll = {
  height: "30vh",
  width: "100%",
  overflowY: "scroll",
}

const ImageHeaderNoticeClient = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="end">
      <img
        src={Logo}
        alt="Capital funding lab logo"
        style={{
          width: "70%",
          height: "70%",
        }}
      />
    </Box>
  )
}

const TitleHeaderNoticeClient = () => {
  return (
    <Box width="90%">
      <p
        style={{
          fontWeight: "700",
          textAlign: "center",
          marginRight: "15%",
          fontSize: "1.3rem",
        }}
      >
        Aviso importante
      </p>
    </Box>
  )
}

const HeaderNoticeClient = () => {
  return (
    <Box display="flex" justifyContent="space-between">
      <ImageHeaderNoticeClient />
      <TitleHeaderNoticeClient />
    </Box>
  )
}

const BodyNoticeClient = ({ withScroll = false }) => {
  return (
    <div style={withScroll ? styleScroll : undefined}>
      <p style={{ marginBottom: "5%" }}>Estimados Clientes,</p>
      <p
        style={{
          textAlign: "justify",
        }}
      >
        Queremos informarles que Emprendimientos Maussan y González, Sociedad
        Anónima Promotora de Inversión de Capital Variable, Institución de
        Financiamiento Colectivo, quien se identifica bajo el nombre comercial
        <span
          style={{
            fontWeight: "700",
          }}
        >
          {" "}
          Capital Funding Lab
        </span>
        , es una Institución de Tecnología Financiera que NO SOLICITA ANTICIPOS,
        ni otorga créditos a través de las Redes Sociales. Debido a que toda
        nuestra operación, funcionamiento, servicios financieros y
        comunicaciones se realizan exclusivamente a través de nuestras
        plataformas oficiales.
        <br />
        <br /> Ante esta situación, exhortamos al público en general a tener
        cuidado y no proporcionar información personal, ni realizar pagos a
        través de aplicaciones de mensajería instantánea. Asimismo, si recibe
        algún mensaje de este tipo, repórtelo inmediatamente al correo
        electrónico:{" "}
        <a href="mailto:info@capitalfundinglab.com" target="_blank">
          info@capitalfundinglab.com
        </a>
        . Adicionalmente, puede reportar cualquier caso sospechoso a la Comisión
        Nacional para la Protección y Defensa de los Usuarios de Servicios
        Financieros (CONDUSEF) a través de su página web{" "}
        <a href="http://www.condusef.gob.mx" target="_blank">
          http://www.condusef.gob.mx
        </a>{" "}
        o al teléfono{" "}
        <a href="tel:01-800-999-8080" target="_blank">
          01-800-999-8080
        </a>
        .
        <br />
        <br /> Agradecemos su atención y colaboración para mantener la seguridad
        de todos nuestros clientes. <br />
        <br />
        Atentamente
        <br />
        <br />
        Capital Funding Lab
      </p>
    </div>
  )
}

export {
  HeaderNoticeClient,
  BodyNoticeClient,
  ImageHeaderNoticeClient,
  TitleHeaderNoticeClient,
}
