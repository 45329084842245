import React, {useRef, useState, useEffect, useCallback} from "react"
import {useTransition, animated} from "react-spring"
import "./title.css"
import {Typography} from "@material-ui/core"

function Title() {
    const ref = useRef([])
    const [items, set] = useState([])
    const transitions = useTransition(items, {
        from: {
            opacity: 0,
            height: 0,
            innerHeight: 0,
            transform: "perspective(600px) rotateX(0deg)",
            color: "#8fa5b6"
        },
        enter: [
            {opacity: 1, height: 60, innerHeight: 60},
            {transform: "perspective(600px) rotateX(180deg)", color: "white"},
            {transform: "perspective(600px) rotateX(0deg)"}
        ],
        leave: [
            {color: "#37DFA1"},
            {innerHeight: 0},
            {opacity: 0, height: 0}
        ],
        update: {color: "white", fontWeight: 900}
    })

    const reset = useCallback(() => {
        ref.current.map(clearTimeout)
        ref.current = []
        set([])
        ref.current.push(
            setTimeout(
                () => set(["Invierte en préstamos", "con garantías reales"]),
                2000
            )
        )
        ref.current.push(
            setTimeout(
                () => set(["Ponemos en contacto a ", "inversionistas y solicitantes ", "de crédito."]),
                7000
            )
        )
        // Ponemos en contacto a inversionistas y solicitantes de crédito
    }, [])

    useEffect(() => void reset(), [])

    return (
        <div>
            {transitions(({innerHeight, ...rest}, item) => (
                <animated.div
                    className="transitions-item"
                    style={rest}
                    onClick={reset}
                >
                    <animated.div style={{overflow: "hidden", height: innerHeight}}>
                        <Typography variant="h1" style={{fontWeight: 700}}>{item}</Typography>
                    </animated.div>
                </animated.div>
            ))}
        </div>
    )
}

export default Title
