import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Typography, Box } from "@material-ui/core"
import DisplayChild from "./DisplayChild"
import Finance from "./utils/finance"

const creditScoreFees = {
  AA: 0.1,
  A: 0.2,
  B: 0.35,
  C: 0.5,
  D: 0.65,
  E: 0.8,
  F: 0.95
}

const openningFees = {
  AA: [3.5, 4.5, 5.5, 7.0, 8.5, 10.0, 11.5, 13.0, 15.0, 17.5, 20.0, 23.0],
  A: [4.5, 5.5, 6.5, 8.0, 9.5, 11.0, 12.5, 14.0, 16.0, 18.5, 21.0, 24.0],
  B: [5.5, 6.5, 7.5, 9.0, 10.5, 12.0, 13.5, 15.0, 17.0, 19.5, 22.0, 25.0],
  C: [6.5, 7.5, 8.5, 10.0, 11.5, 13.0, 14.5, 16.0, 18.0, 20.5, 23.0, 26.0],
  D: [7.5, 8.5, 9.5, 11.0, 12.5, 14.0, 15.5, 17.0, 19.0, 21.5, 24.0, 27.0],
  E: [8.5, 9.5, 10.5, 12.0, 13.5, 15.0, 16.5, 18.0, 20.0, 22.5, 25.0, 28.0],
  F: [9.5, 10.5, 11.5, 13.0, 14.5, 16.0, 17.5, 19.0, 21.0, 23.5, 26.0, 29.0]
}

const tnaFees = {
  AA: [7.0, 8.0, 9.0, 10.0, 11.0, 11.5, 12.0, 12.5, 13.0, 13.5, 14.0, 14.5],
  A: [8.5, 9.5, 10.5, 11.5, 12.5, 13.0, 13.5, 14.0, 14.5, 15.0, 15.5, 16.0],
  B: [10.0, 11.0, 12.0, 13.0, 14.0, 14.5, 15.0, 15.5, 16.0, 16.5, 17.0, 17.5],
  C: [13.0, 14.0, 15.0, 16.0, 17.0, 17.5, 18.0, 18.5, 19.0, 19.5, 20.0, 20.5],
  D: [15.5, 16.5, 17.5, 18.5, 19.5, 20.0, 20.5, 21.0, 21.5, 22.0, 22.5, 23.0],
  E: [18.5, 19.5, 20.5, 21.5, 22.5, 23.0, 23.5, 24.0, 24.5, 25.0, 25.5, 26.0],
  F: [24.5, 25.5, 26.5, 27.5, 28.5, 29.0, 29.5, 30.0, 30.5, 31.0, 31.5, 32.0]
}
const arrayHasValues = value =>
  value && Array.isArray(value) && value.length > 0

const average = arr => {
  if (arrayHasValues(arr)) {
    if (arr.length) {
      var sum = arr.reduce(function(a, b) {
        return a + b
      })
      return sum / arr.length
    }
  }

  return 0
}

const roundNumber = (number, length) => {
  return parseFloat(number.toFixed(length))
}

let finance = new Finance()

const Display = ({ years, amount, score, destiny }) => {
  const [NTERMS, setNTERMS] = useState(null)
  const [TNM, setTNM] = useState(0)
  const [TNA, setTNA] = useState()
  const [A, setA] = useState(0)
  const [B, setB] = useState(0)
  const [QUOTE, setQUOTE] = useState(0)
  const [realComision, setRealComision] = useState(0)
  const [totalRequest, setTotalRequest] = useState(0)
  const [TABLA, setTABLA] = useState([])

  const calculateCreditScoreFee = () => {
    setTNA((creditScoreFees[score] * years * amount) / 100)
  }

  const calculateComission = () => {
    setRealComision((openningFees[score][NTERMS] * amount) / 100)
  }

  const calculateTotalRequest = () => {
    setTotalRequest(realComision + amount)
  }

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0
  })

  const calculateOpenningFee = () => {
    return (
      <Typography variant="h4">
        <strong>{(formatter.format(totalRequest))}</strong>
      </Typography>
    )
  }

  const calculateTnaFee = () => {
    return tnaFees[score][NTERMS]
  }

  const percentageTNA = () => {
    return (
      <Typography variant="h4">
        <strong>{calculateTnaFee()}%</strong>
      </Typography>
    )
  }

  const solicitadedAmmount = () => {
    return (
      <Typography variant="h4">
        <strong>{formatter.format(amount)}</strong>
      </Typography>
    )
  }

  const calculateA = () => {
    setA(((amount + realComision) * TNM) / 100)
  }

  const calculateB = () => {
    setB(1 - (1 + TNM / 100) ** -years)
  }

  const calculateQuote = () => {
    setQUOTE((A / B).toFixed(2))
  }

  const calculateTNM = () => {
    setTNM(calculateTnaFee() / 12)
  }

  const calculateTablaDeAmortizacion = () => {
    let tabla = {
      period: new Array(years),
      debt: new Array(years),
      payment: new Array(years),
      interest: new Array(years),
      managementFee: new Array(years),
      iva: new Array(years),
      catIva: new Array(years),
      catsIva: new Array(years)
    }

    let interest = (TNM * totalRequest) / 100
    let payment = QUOTE - interest

    let managementFee = TNA / years
    let iva = (managementFee + interest) * (16 / 100)
    let catIva = interest + payment + managementFee + iva
    let catsIva = interest + payment + managementFee

    let debt = totalRequest

    for (let i = 0; i < years; i++) {
      debt = debt - payment

      tabla["period"][i] = i
      tabla["debt"][i] = debt
      tabla["interest"][i] = interest
      tabla["payment"][i] = payment
      tabla["managementFee"][i] = managementFee
      tabla["iva"][i] = iva
      tabla["catIva"][i] = roundNumber(catIva, 2)
      tabla["catsIva"][i] = roundNumber(catsIva, 2)

      interest = (TNM * debt) / 100
      payment = QUOTE - interest
      managementFee = TNA / years
      iva = (managementFee + interest) * (16 / 100)
      catIva = interest + payment + managementFee + iva
      catsIva = interest + payment + managementFee
    }
    setTABLA(tabla)
  }

  const calculateAverage = () => {
    let averageQuota = average(TABLA["catIva"])
    averageQuota = Number(averageQuota.toFixed(2))
    return (
      <Typography variant="h4">
        <strong>{(formatter.format(averageQuota))}</strong>
      </Typography>
    )
  }

  const calculatePaltformCommision = () => {
    return (
      <Typography variant="h4">
        <strong>{(formatter.format(realComision))}</strong>
      </Typography>
    )
  }

  const calculateFinancialCost = () => {
    let catIvas = [-amount]
    let catIVA = TABLA["catIva"]
    if (arrayHasValues(catIVA)) {
      catIvas = catIvas.concat(catIVA.slice(0))
      let catIvaPercent = finance.IRR(catIvas, 0.0001)
      let costAfterTaxes = (1 + catIvaPercent) ** 12 - 1
      costAfterTaxes = costAfterTaxes * 100
      costAfterTaxes = Number(costAfterTaxes.toFixed(2))
      return (
        <Typography variant="h4">
          <strong>{costAfterTaxes}%</strong>
        </Typography>
      )
    }
    return (
      <Typography variant="h4">
        <strong>0%</strong>
      </Typography>
    )
  }

  const calculateFinancialCostS = () => {
    let catsIvas = [-amount]
    let catsIVA = TABLA["catsIva"]
    if (arrayHasValues(catsIVA)) {
      catsIvas = catsIvas.concat(TABLA["catsIva"].slice(0))
      let catsIvaPercent = finance.IRR(catsIvas, 0.0001)
      let costBeforeTaxes = (1 + catsIvaPercent) ** 12 - 1
      costBeforeTaxes = costBeforeTaxes * 100
      costBeforeTaxes = Number(costBeforeTaxes.toFixed(2))
      return (
        <Typography variant="h4">
          <strong>{costBeforeTaxes}%</strong>
        </Typography>
      )
    }
    return (
      <Typography variant="h4">
        <strong>0%</strong>
      </Typography>
    )
  }

  const calculateTerms = (callback) => {
    let tmpTrms = 0;
    if (6 < years && years < 12) {
      tmpTrms = 0;
    } else if (11 < years && years < 18) {
      tmpTrms = 1
    } else if (17 < years && years < 24) {
      tmpTrms = 2
    } else if (23 < years && years < 36) {
      tmpTrms = 3
    } else if (35 < years && years < 48) {
      tmpTrms = 4
    } else if (47 < years && years < 60) {
      tmpTrms = 5
    } else if (59 < years && years < 72) {
      tmpTrms = 6
    } else if (71 < years && years < 84) {
      tmpTrms = 7
    } else if (83 < years && years < 96) {
      tmpTrms = 8
    } else if (95 < years && years < 108) {
      tmpTrms = 9
    } else if (107 < years && years < 120) {
      tmpTrms = 10
    } else if (119 < years && years < 132) {
      tmpTrms = 11
    } else if (131 < years && years < 144) {
      tmpTrms = 12
    } else if (1443 < years && years < 156) {
      tmpTrms = 13
    } else if (155 < years && years < 168) {
      tmpTrms = 14
    } else if (167 < years && years < 180) {
      tmpTrms = 15
    } else if (179 < years && years < 192) {
      tmpTrms = 16
    } else if (191 < years && years < 204) {
      tmpTrms = 17
    } else if (203 < years && years < 216) {
      tmpTrms = 18
    } else if (215 < years && years < 228) {
      tmpTrms = 19
    } else if (227 < years && years < 240) {
      tmpTrms = 20
    }

    setNTERMS(tmpTrms)
  }

  useEffect(() => {
    calculateTerms();
    calculateComission();
    calculateTotalRequest()
    calculateTNM()
    calculateCreditScoreFee()
    calculateA()
    calculateB()
    calculateQuote()
    calculateTablaDeAmortizacion()
    calculateAverage()
    calculateFinancialCost()
    calculateFinancialCostS()
  }, [NTERMS, realComision, totalRequest, TNM, TNA, A, B, QUOTE, years, amount, score])

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <DisplayChild func={calculateOpenningFee()} text="Monto Solicitado" />
        <DisplayChild func={solicitadedAmmount()} text="Monto a acreditar" />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <DisplayChild func={calculateAverage()} text="Promedio de cuotas" />
        <DisplayChild
          func={calculatePaltformCommision()}
          text="Uso de plataforma"
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <DisplayChild func={percentageTNA()} text="TNA" />
        <DisplayChild func={calculateFinancialCostS()} text="CAT* S/IVA" />
        <DisplayChild func={calculateFinancialCost()} text="CAT* C/IVA" />
      </Box>
    </Box>
  )
}

Display.propTypes = {
  years: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired
}

export default Display
