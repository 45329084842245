import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, Button } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Calculator from "./Calculator"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0, 10, 0)
  },
  textMargin: {
    padding: "10px 2.3rem",
    margin: "10px auto",
    maxWidth: "480px"
  },
  textStyle2: {
    padding: theme.spacing(3, 0)
  },
  buttonR: {
    borderRadius: "50px",
    margin: theme.spacing(6, 0)
  },
  container: {
    padding: theme.spacing(0, 6)
  },
  resText:{
    [theme.breakpoints.down('md')]: {
      textAlign:'center'
    },
  },
  containerButton:{
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center'
    },
  }
}))

export default function Investment() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container>
        <Grid container justify="center">
          <Grid item container spacing={4} lg={10} justify="space-between">
            <Grid item xs={12} lg={6}>
              <Typography variant="h3" className={classes.textStyle2 + ' ' + classes.resText}>
                ¿Te gustaría <strong>conocer más?</strong>
              </Typography>
              <Typography  className={classes.resText}>
                Un profesional de CFL estará encantado de responder cualquier
                pregunta que pueda tener sobre nuestras ofertas y productos.
              </Typography>
              <Grid>
                <div className={classes.textStyle2}>
                  <Typography variant="body1" color="textSecondary" className={classes.resText}>
                    Email:
                    <br />
                    <a href="mailto:info@capitalfundinglab.com" style={{textDecoration:'none'}}>
                    info@capitalfundinglab.com
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <Typography variant="h2" className={classes.typStyle + ' ' + classes.resText}>
                  Los primeros pasos son <br />
                  los <strong>más importantes</strong>
                </Typography>
              </Grid>
              <Grid container className={classes.containerButton}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonR}
                  href={`${process.env.GATSBY_APP_LENDER}/register/email?profileType=personal&lang=es-mx`}
                >
                  Regístrate como inversor
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={"auto"}>
              <Box maxWidth="350px">
                <Calculator />
                <Typography variant="body2" className={classes.resText}>
                  El resultado de "Calcula tu inversión", es un estimado que se
                  basa exclusivamente en la información que incorporaste en el
                  formulario. Tu capital está en riesgo y no está protegido por
                  ningún esquema de compensación gubernamental de servicios
                  financieros.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
