import React, { useState } from "react"
import { Box, Container, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import {
  BodyNoticeClient,
  ImageHeaderNoticeClient,
  TitleHeaderNoticeClient,
} from "./NoticeClient"

const BannerNoticeClient = () => {
  const [open, setOpen] = useState(false) // banner is off
  return (
    <>
      {open ? (
        <Container
          style={{
            backgroundColor: "white",
            marginTop: "6%",
            border: "1px solid grey",
            borderRadius: "10px",
          }}
        >
          <Box mx="5%">
            <Box display="flex" justifyContent="space-between">
              <ImageHeaderNoticeClient />
              <TitleHeaderNoticeClient />
              <Box
                height="10%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignSelf="end"
              >
                <IconButton
                  aria-label="close"
                  style={{
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box py="1%">
              <BodyNoticeClient withScroll={true} />
            </Box>
          </Box>
        </Container>
      ) : null}
    </>
  )
}

export { BannerNoticeClient }
