import React, {useState} from "react"
import "./DataVoice.css"
import BotonChat from "../../assets/DataVoice/Vector.png"
import {Button} from "@material-ui/core";


function DataVoice() {
    const [open, setOpen] = useState(false);

    const openChat = () => {
        setOpen(true)
    }

    const closeChat = () => {
        setOpen(false)
    }

    return (
        <>
            {" "}
            {open ? (
                <div className="form-box open" id="divChat">
                    <a className="close" onClick={closeChat}></a>
                    <iframe
                        className="frameChat"
                        src={process.env.GATSBY_DATA_VOICE_ENDPOINT}
                    />
                </div>
            ) : (
                <Button
                    className="floating-btn clicked"
                    data-toggle="tooltip"
                    data-placement="left"
                    style={{
                        position: "fixed",
                        cursor: "pointer",
                        background: '#07212E',
                        color: 'white',
                        fontSize: '15px',
                        padding: '8px 22px',
                        bottom: '70px',
                    }}
                    onClick={openChat}
                >
                    ¿Necesitas ayuda?
                    <img
                        src={BotonChat}
                        alt={'img'}
                        style={{
                            left: '10%',
                            right: '10%',
                            top: '15%',
                            bottom: '15%',
                        }}
                    />
                </Button>
            )}
        </>
    )
}

export default DataVoice
