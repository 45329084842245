import React, { useState } from "react"
import Display from "./Display"
import { Typography, Box, Button, Card, Slider } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import "./Calculator.css"
import "react-input-range/lib/css/index.css"

const CalculatorButton = withStyles({
  root: {
    marginRight: 8,
    borderRadius: 4
  }
})(Button)

const CalculatorSlider = withStyles({
  root: {
    color: "#291C6",
    height: 10,
    padding: "8px 0",
    width: "100%"
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: -8,
    marginLeft: -12
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 6px)",
    top: 32,
    textAlign: "center",
    fontSize: "18px !important",
    fontWeight: 600,
    "& *": {
      background: "transparent",
      color: "#000"
    }
  },
  track: {
    height: 4
  },
  rail: {
    height: 4,
    opacity: 0.5,
    backgroundColor: "#bfbfbf"
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor"
  }
})(Slider)

function Calculator() {
  const [amountValue, setAmountValue] = useState(10000)
  const [yearsValue, setYearsValue] = useState(1)
  const [interestAmount, handleInterestChange] = useState(2.5)

  const handleChangeAmount = (event, amountValue) => {
    setAmountValue(amountValue);
  }

  const handleChangeYears = (event, yearsValue) => {
    setYearsValue(yearsValue);
  }

  return (
    <Card raised style={{ marginBottom: "16px", width:'100%' }}>
      <Box py={4} px={4}>
        <Typography variant="h3" align="center" gutterBottom>
          Calcula <strong>tu inversión</strong>
        </Typography>
        <Typography
          variant="body2"
          id="amount-value"
          gutterBottom
          style={{ paddingTop: 16 }}
        >
          ¿Qué monto deseas invertir?
        </Typography>
        <CalculatorSlider
          value={amountValue}
          defaultValue={1000}
          aria-labelledby="amount-value"
          step={100}
          min={20}
          max={100000}
          marks={[{ value: 100000, label: "MXN" }]}
          valueLabelDisplay="on"
          onChange={handleChangeAmount}
        />
        <Typography
          variant="body2"
          id="years-value"
          gutterBottom
          style={{ paddingTop: 24 }}
        >
          ¿Por cuánto tiempo?
        </Typography>
        <CalculatorSlider
          defaultValue={1}
          value={yearsValue}
          aria-labelledby="years-value"
          step={1}
          min={1}
          max={12}
          marks={[{ value: 12, label: "Meses" }]}
          valueLabelDisplay="on"
          onChange={handleChangeYears}
        />
        <Typography
          variant="body2"
          id="years-value"
          gutterBottom
          style={{ paddingTop: 24 }}
        >
          ¿Cuál producto te interesa?
        </Typography>
        <Box display="flex" justifyContent="flex-start" pb={2}>
          <CalculatorButton
            size="small"
            variant="outlined"
            color="primary"
            value={2.5}
            onClick={() => handleInterestChange(2.5)}
          >
            ACCESO
          </CalculatorButton>
          <CalculatorButton
            size="small"
            variant="outlined"
            color="primary"
            value={8}
            onClick={() => handleInterestChange(8)}
          >
            UNO
          </CalculatorButton>
          <CalculatorButton
            size="small"
            variant="outlined"
            color="primary"
            value={9}
            onClick={() => handleInterestChange(9)}
          >
            MERCADO
          </CalculatorButton>
        </Box>
        <Display
          years={yearsValue}
          amount={amountValue}
          interest={interestAmount}
        />
      </Box>
    </Card>
  )
}

export default Calculator
