import React from "react"
import { TextField,MenuItem, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

export default function Selector({data, id, label, helper, value, setValue}) {
    const classes = useStyles();
 
  
    const handleChange = (event) => {
        setValue(event.target.value);
    };
  return (
    <div style={{width:'100%'}}>
        <TextField
          id={id}
          select
          fullwidth
          label={label}
          value={value}
          onChange={handleChange}
          helperText={helper}
          variant="outlined"
          className={classes.formControl}
        >
          {data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
    </div>
  )
}
