import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CardsInvestment from "./CardsInvestment"
import "./Ofrecemos.css"
import bgBlob from "../assets/Investment/investment-background-top.svg"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0, 2, 0),
    background: `url(${bgBlob})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 15,
    textAlign: "rigth",
    width: 350,
  },
  marginText: {
    marginBottom: theme.spacing(4),
  },
}))

export default function HowItWorks() {
  const classes = useStyles()
  return (
    <div className={classes.root + " backgroundO"} id="Inversion">
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid className={classes.description} item xs={12}>
            <Typography align="center" variant="overline" component="p">
              Inversión
            </Typography>
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              className={classes.marginText}
            >
              ¿Qué ofrecemos a <strong>nuestros inversionistas?</strong>
            </Typography>
          </Grid>
          <Grid
            item
            lg={10}
            container
            spacing={4}
            justify="space-around"
            alignItems="center"
          >
            <CardsInvestment
              type="investment"
              title="Acceso"
              content1="Rendimiento estimado anual"
              content2="Sin plazo mínimo."
              content3="La inversión se reinvierte de forma automática para maximizar su
            retorno."
              number="2.5%"
              union="CFL"
              color="primary"
            />

            <CardsInvestment
              type="investment"
              title="Uno"
              content1="Rendimiento estimado anual"
              content2="Plazo de 1 año."
              content3="La inversión se reinvierte de forma automática para maximizar su
            retorno."
              number="8%"
              union="CFL"
              color="primary"
            />

            <CardsInvestment
              type="investment"
              title="Mercado"
              content1="Rendimiento estimado anual"
              content2="Plazo de 3 años."
              content3=" La inversión se reinvierte de forma automática para maximizar su
            retorno."
              number="9%"
              union="CFL"
              color="primary"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
