import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Box
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 300
  },
  cardTitle: {
    fontFamily: "Ubuntu",
    fontSize: 23,
    fontWeight: 700,
    textTransform: "uppercase",
    display: "block"
  },
  money: {
    fontFamily: "Ubuntu",
    fontSize: 48,
    fontWeight: 700,
    display: "block",
    lineHeight: "48px"
  },
  spaceY: {
    margin: theme.spacing(2, 0)
  }
}))

export default function CardsCredit(props) {
  const classes = useStyles()

  return (
    <Grid item>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography variant="body2" align="center">
              Préstamo
            </Typography>
            <Typography
              variant="h3"
              align="center"
              className={classes.cardTitle}
            >
              {props.title}
            </Typography>
            <Box pt={3}>
              <Typography gutterBottom variant="body1" align="center">
                {props.union}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              align="center"
              color={props.color}
              className={classes.money}
            >
              {props.number}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              component="p"
              className={classes.spaceB}
            >
              {props.content1}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              component="p"
              className={classes.spaceY}
            >
              {props.content2}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              component="p"
            >
              <span>{props.content3}</span>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
CardsCredit.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
  union: PropTypes.string,
  prestamo: PropTypes.string
}
