import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import HowItWorks from "../components/HowItWorks"
import Ofrecemos from "../components/Ofecemos"
import OfrecemosSol from "../components/OfrecemosSol"
import Investment from "../components/Investment"
import Home from "../components/Home"
import Finance from "../components/Finance"
import DataVoice from "../components/DataVoice"
import "./index.css"
import { ModalNotice } from "../components/NoticeClient/ModalNotice"

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')"
          rel="stylesheet"
        />
        <title>CFL</title>
      </Helmet>
      <ModalNotice />
      <Home />
      <HowItWorks />
      <Ofrecemos />
      <Investment />
      <OfrecemosSol />
      <Finance />
      <DataVoice />
    </Layout>
  )
}
