import React, { useState } from "react"
import DisplayFinace from "./DisplayFinace"
import { Typography, Button, Card, Slider, Box } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import Select from "./Select"
import "./CalculatorFinance.css"
import "react-input-range/lib/css/index.css"

const CalculatorButton = withStyles({
  root: {
    marginRight: 8,
    borderRadius: 4
  }
})(Button)

const CalculatorSlider = withStyles({
  root: {
    color: "#05A2A7",
    height: 10,
    padding: "8px 0",
    width: "100%"
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: -8,
    marginLeft: -12
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 6px)",
    top: 32,
    textAlign: "center",
    fontSize: "18px !important",
    fontWeight: 600,
    "& *": {
      background: "transparent",
      color: "#000"
    }
  },
  track: {
    height: 4
  },
  rail: {
    height: 4,
    opacity: 0.5,
    backgroundColor: "#bfbfbf"
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor"
  }
})(Slider)

const dataScore = [
  { value: "AA", label: "AA - Sobresaliente" },
  { value: "A", label: "A - Excelente" },
  { value: "B", label: "B - Superior" },
  { value: "C", label: "C - Muy Bueno" },
  { value: "D", label: "D - Bueno" },
  { value: "E", label: "E - Adecuado" },
  { value: "F", label: "F - Aceptable" }
]
const dataDestiny = [
  { value: 9, label: "Refinanciamiento de deudas anteriores" },
  { value: 7, label: "Remodelación" },
  { value: 4, label: "Invertir en Educación" },
  { value: 2, label: "Cambio de vehículo" },
  { value: 3, label: "Gastos médicos" },
  { value: 10, label: "Refinanciamiento tarjetas de crédito" },
  { value: 6, label: "Viaje" },
  { value: 5, label: "Gastos de boda" },
  { value: 12, label: "Gastos de celebración" },
  { value: 1, label: "Decoración" },
  { value: 11, label: "Gastos de mudanza" },
  { value: 100, label: "Otros destinos" }
]
const CalculatorFinace = () => {
  const [amountValue, setAmountValue] = useState(100000)
  const [yearsValue, setYearsValue] = useState(12)
  const [persontype, handlePersonChange] = useState(0)
  const [value, setValue] = useState("AA")
  const [valueDestiny, setValueDestiny] = useState(1)

  const handleChangeAmount = (event, amountValue) => {
    setAmountValue(amountValue)
  }

  const handleChangeYears = (event, yearsValue) => {
    setYearsValue(yearsValue)
  }

  return (
    <Card raised style={{ marginBottom: "16px" }}>
      <Box py={4} px={4}>
        <Typography variant="h3" align="center" gutterBottom>
          Calcula <strong>tu crédito</strong>
        </Typography>

        <Typography
          variant="body2"
          id="years-value"
          gutterBottom
          style={{ paddingTop: 24 }}
        >
          ¿Cuál producto te interesa?
        </Typography>
        <Box display="flex" justifyContent="center">
          <CalculatorButton
            size="small"
            variant="outlined"
            color="secondary"
            value={0}
            onClick={() => handlePersonChange(0)}
          >
            PERSONAL
          </CalculatorButton>
          <CalculatorButton
            size="small"
            variant="outlined"
            color="secondary"
            value={1}
            onClick={() => handlePersonChange(1)}
          >
            COMERCIAL
          </CalculatorButton>
        </Box>
        <Typography
          variant="body2"
          id="amount-value"
          gutterBottom
          style={{ paddingTop: 16 }}
        >
          ¿Qué monto deseas financiar?
        </Typography>
        {persontype === 0 ? (
          <CalculatorSlider
            value={amountValue}
            defaultValue={200}
            aria-labelledby="amount-value"
            step={100}
            min={100000}
            max={300000}
            marks={[{ value: 300000, label: "MXN" }]}
            valueLabelDisplay="on"
            onChange={handleChangeAmount}
          />
        ) : (
          <CalculatorSlider
            defaultValue={200}
            value={amountValue}
            aria-labelledby="amount-value"
            step={100}
            min={100000}
            max={10000000}
            marks={[{ value: 10000000, label: "MXN" }]}
            valueLabelDisplay="on"
            onChange={handleChangeAmount}
          />
        )}
        <Typography
          variant="body2"
          id="years-value"
          gutterBottom
          style={{ paddingTop: 24 }}
        >
          ¿Por cuánto tiempo?
        </Typography>
        {persontype === 0 ? (
          <CalculatorSlider
            value={yearsValue}
            defaultValue={1}
            aria-labelledby="years-value"
            step={1}
            min={6}
            max={120}
            marks={[{ value: 120, label: "Meses" }]}
            valueLabelDisplay="on"
            onChange={handleChangeYears}
          />
        ) : (
          <CalculatorSlider
            value={yearsValue}
            defaultValue={1}
            aria-labelledby="years-value"
            step={1}
            min={12}
            max={240}
            marks={[{ value: 240, label: "Meses" }]}
            valueLabelDisplay="on"
            onChange={handleChangeYears}
          />
        )}
        <Box display="flex" justifyContent="center">
          <Select
            data={dataScore}
            id={1}
            label={"¿Cuál es tu perfil crediticio?"}
            value={value}
            setValue={setValue}
          />
        </Box>
        <Box display="flex" justifyContent="center" pb={1}>
          <Select
            data={dataDestiny}
            id={1}
            label={"¿Cuál es el destino de tu crédito?"}
            value={valueDestiny}
            setValue={setValueDestiny}
          />
        </Box>
        <DisplayFinace years={yearsValue} amount={amountValue} score={value} destiny={valueDestiny} />
        <Typography
          variant="body2"
          gutterBottom
          style={{ fontSize: 9, paddingTop: 10 }}
        >
          * El Coste Anual Total (CAT) es una medida estandarizada del costo de financiamiento, expresado en términos porcentuales anuales que, para fines informativos y de comparación, incorpora la totalidad de los costos y gastos inherentes a los créditos que otorgan las instituciones.
        </Typography>
      </Box>
    </Card>
  )
}

export default CalculatorFinace
